// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bN2Fa5FgSGHUh4zYUYzt {
  font-family: 'Roboto Mono', Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-size: 1rem; /* theme.fonts.size.body */
  overflow-wrap: break-word;
  white-space: pre-wrap;
}
`, "",{"version":3,"sources":["webpack://./src/components/event-notifications/event-notification-types/EmailNotificationSummary.css"],"names":[],"mappings":"AAAA;EACE,6EAA6E;EAC7E,eAAe,EAAE,0BAA0B;EAC3C,yBAAyB;EACzB,qBAAqB;AACvB","sourcesContent":[":local(.bodyPreview) {\n  font-family: 'Roboto Mono', Menlo, Monaco, Consolas, 'Courier New', monospace;\n  font-size: 1rem; /* theme.fonts.size.body */\n  overflow-wrap: break-word;\n  white-space: pre-wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bodyPreview": `bN2Fa5FgSGHUh4zYUYzt`
};
export default ___CSS_LOADER_EXPORT___;
