// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DqBObyDjYFhBwILgUW_Q .panel-body {
  padding: 20px 10px;
}

.DqBObyDjYFhBwILgUW_Q ul {
  padding-left: 1em;
}
`, "",{"version":3,"sources":["webpack://./src/components/event-definitions/event-definition-form/field-value-providers/TemplateFieldValueProviderPreview.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[":local(.templatePreview) .panel-body {\n  padding: 20px 10px;\n}\n\n:local(.templatePreview) ul {\n  padding-left: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"templatePreview": `DqBObyDjYFhBwILgUW_Q`
};
export default ___CSS_LOADER_EXPORT___;
