// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AGNNVtf0vXX5QH3GraEY {
  margin-right: 15px;
  margin: 3px 15px 0 0;
}
`, "",{"version":3,"sources":["webpack://./src/views/components/widgets/MessageWidgets.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,oBAAoB;AACtB","sourcesContent":[":local(.iconMargin) {\n  margin-right: 15px;\n  margin: 3px 15px 0 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconMargin": `AGNNVtf0vXX5QH3GraEY`
};
export default ___CSS_LOADER_EXPORT___;
