// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.z6LAsSk67C9Z9TP6Sw_Z .panel-title {
  font-size: 1rem; /* theme.fonts.size.body */
}

.z6LAsSk67C9Z9TP6Sw_Z .panel-title > a {
  cursor: pointer;
  display: block;
}

.z6LAsSk67C9Z9TP6Sw_Z .panel-body {
  padding: 10px;
}

.z6LAsSk67C9Z9TP6Sw_Z ul {
  padding-left: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/event-definitions/common/HelpPanel.css"],"names":[],"mappings":"AAAA;EACE,eAAe,EAAE,0BAA0B;AAC7C;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;AACjB","sourcesContent":[":local(.helpPanel) .panel-title {\n  font-size: 1rem; /* theme.fonts.size.body */\n}\n\n:local(.helpPanel) .panel-title > a {\n  cursor: pointer;\n  display: block;\n}\n\n:local(.helpPanel) .panel-body {\n  padding: 10px;\n}\n\n:local(.helpPanel) ul {\n  padding-left: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpPanel": `z6LAsSk67C9Z9TP6Sw_Z`
};
export default ___CSS_LOADER_EXPORT___;
