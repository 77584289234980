// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FExXmIJ_52cj0vrHyaEp {
  margin: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/views/components/searchbar/saved-search/SavedSearchForm.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb","sourcesContent":[":local(.button) {\n  margin: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `FExXmIJ_52cj0vrHyaEp`
};
export default ___CSS_LOADER_EXPORT___;
