// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qWBqb_23sgqkD946642Z {
  position: absolute;
  z-index: 999; /* So it displays below bootstrap dropdowns */
}

.VEeyO9k8vNGGhxOuqVkQ .leaflet-control-container {
  display: none;
}

.leaflet-top,
.leaflet-bottom {
  z-index: 999; /* So it displays below bootstrap dropdowns */
}

@media print {
  .KoTenzOWhTf1f7jkioVR {
    margin: 0 auto;
    page-break-inside: avoid;
  }

  /* Hide zoom controls */
  .leaflet-control-container {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/views/components/visualizations/worldmap/MapVisualization.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY,EAAE,6CAA6C;AAC7D;;AAEA;EACE,aAAa;AACf;;AAEA;;EAEE,YAAY,EAAE,6CAA6C;AAC7D;;AAEA;EACE;IACE,cAAc;IACd,wBAAwB;EAC1B;;EAEA,uBAAuB;EACvB;IACE,aAAa;EACf;AACF","sourcesContent":[":local(.overlay) {\n  position: absolute;\n  z-index: 999; /* So it displays below bootstrap dropdowns */\n}\n\n:local(.mapLocked) .leaflet-control-container {\n  display: none;\n}\n\n.leaflet-top,\n.leaflet-bottom {\n  z-index: 999; /* So it displays below bootstrap dropdowns */\n}\n\n@media print {\n  :local(.map) {\n    margin: 0 auto;\n    page-break-inside: avoid;\n  }\n\n  /* Hide zoom controls */\n  .leaflet-control-container {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `qWBqb_23sgqkD946642Z`,
	"mapLocked": `VEeyO9k8vNGGhxOuqVkQ`,
	"map": `KoTenzOWhTf1f7jkioVR`
};
export default ___CSS_LOADER_EXPORT___;
