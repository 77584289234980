// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Restore bootstrap heading margins because our stylesheet is :shit: */
.Qgqa5YAOjl_LIS9aGdk3 h1,
.Qgqa5YAOjl_LIS9aGdk3 h2,
.Qgqa5YAOjl_LIS9aGdk3 h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.Qgqa5YAOjl_LIS9aGdk3 h4,
.Qgqa5YAOjl_LIS9aGdk3 h5,
.Qgqa5YAOjl_LIS9aGdk3 h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/views/pages/StyleFixups.css"],"names":[],"mappings":"AAAA,uEAAuE;AACvE;;;EAGE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;;;EAGE,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":["/* Restore bootstrap heading margins because our stylesheet is :shit: */\n:local(.bootstrapHeading) h1,\n:local(.bootstrapHeading) h2,\n:local(.bootstrapHeading) h3 {\n  margin-top: 20px;\n  margin-bottom: 10px;\n}\n\n:local(.bootstrapHeading) h4,\n:local(.bootstrapHeading) h5,\n:local(.bootstrapHeading) h6 {\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bootstrapHeading": `Qgqa5YAOjl_LIS9aGdk3`
};
export default ___CSS_LOADER_EXPORT___;
