// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wp9nUw71uYu2kck_XycC span:not(:last-child)::after {
  content: ', ';
}
`, "",{"version":3,"sources":["webpack://./src/components/event-definitions/event-definition-types/FilterAggregationSummary.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf","sourcesContent":[":local(.streamList) span:not(:last-child)::after {\n  content: ', ';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"streamList": `wp9nUw71uYu2kck_XycC`
};
export default ___CSS_LOADER_EXPORT___;
