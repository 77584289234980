// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rKKIeiSYDcRNaNEaL85y {
  margin-bottom: 15px;
  overflow-wrap: break-word;
}
`, "",{"version":3,"sources":["webpack://./src/components/event-notifications/event-notification-types/LegacyNotificationCommonStyles.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,yBAAyB;AAC3B","sourcesContent":[":local(.legacyNotificationAlert) {\n  margin-bottom: 15px;\n  overflow-wrap: break-word;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"legacyNotificationAlert": `rKKIeiSYDcRNaNEaL85y`
};
export default ___CSS_LOADER_EXPORT___;
