// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QSHpvNKPLbhcL9XmEhhU {
  display: flex;
  justify-content: space-between;
}

.j9AR9KIYOoQokjhQgKvg {
  margin-top: 5px;
  margin-bottom: 5px;
}

.OEtwRH8_D2wSh4zSe35P {
  margin-right: 5px;
  width: 100%;
}

.Ptn3S3n2DhOJZlpLxQ8U {
  font-size: 1rem; /* theme.fonts.size.body */
  padding-top: 0px;
  padding-right: 0px;
  float: right;
}

.mWIeXfwAHVUqyO9KpNv8 {
  font-size: 1rem; /* theme.fonts.size.body */
}

.i93VpIsNwxNSYHhnrhdm {
  display: inline-block;
  overflow: visible;
  width: 100%;
}

.Mt_DKChL9e_UySBfolGR {
  margin-top: 2em;
}
`, "",{"version":3,"sources":["webpack://./src/views/components/messagelist/decorators/decoratorStyles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,eAAe,EAAE,0BAA0B;EAC3C,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,eAAe,EAAE,0BAA0B;AAC7C;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,eAAe;AACjB","sourcesContent":[":local(.decoratorBox) {\n  display: flex;\n  justify-content: space-between;\n}\n\n:local(.addDecoratorButtonContainer) {\n  margin-top: 5px;\n  margin-bottom: 5px;\n}\n\n:local(.addDecoratorSelect) {\n  margin-right: 5px;\n  width: 100%;\n}\n\n:local(.helpLink) {\n  font-size: 1rem; /* theme.fonts.size.body */\n  padding-top: 0px;\n  padding-right: 0px;\n  float: right;\n}\n\n:local(.helpPopover) {\n  font-size: 1rem; /* theme.fonts.size.body */\n}\n\n:local(.decoratorListContainer) {\n  display: inline-block;\n  overflow: visible;\n  width: 100%;\n}\n\n:local(.noDecoratorsAlert) {\n  margin-top: 2em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"decoratorBox": `QSHpvNKPLbhcL9XmEhhU`,
	"addDecoratorButtonContainer": `j9AR9KIYOoQokjhQgKvg`,
	"addDecoratorSelect": `OEtwRH8_D2wSh4zSe35P`,
	"helpLink": `Ptn3S3n2DhOJZlpLxQ8U`,
	"helpPopover": `mWIeXfwAHVUqyO9KpNv8`,
	"decoratorListContainer": `i93VpIsNwxNSYHhnrhdm`,
	"noDecoratorsAlert": `Mt_DKChL9e_UySBfolGR`
};
export default ___CSS_LOADER_EXPORT___;
