// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
 * Copyright (C) 2020 Graylog, Inc.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the Server Side Public License, version 1,
 * as published by MongoDB, Inc.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * Server Side Public License for more details.
 *
 * You should have received a copy of the Server Side Public License
 * along with this program. If not, see
 * <http://www.mongodb.com/licensing/server-side-public-license>.
 */
.EM8rNPeHI3I13MdYidzw {
  max-width: 500px;
  min-width: 350px;
}

/* styles for table columns */
.oNIxQlLcjvvFY27jyODI {
  width: 15%;
}
.jXZIhUCAUZtfWZKKiLy0 {
  max-width: 50%;
  width: 35%;
}
.Ii_DwC6G4wRm7thBYSKE {
  width: 15%;
}
.q54g6XMj1D9X2J48jgrw {
  width: 15%;
}
.wFLgXxYT9cnZwrNRg6DJ {
  width: 15%;
}
.mk2GxDtbjbYu9RWQh_nL {
  min-width: 100px;
}

overviewTable {
  width: 100%;
  table-layout: fixed;
}
`, "",{"version":3,"sources":["webpack://./src/components/lookup-tables/Overview.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;EAeE;AACF;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA,6BAA6B;AAC7B;EACE,UAAU;AACZ;AACA;EACE,cAAc;EACd,UAAU;AACZ;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB","sourcesContent":["/*\n * Copyright (C) 2020 Graylog, Inc.\n *\n * This program is free software: you can redistribute it and/or modify\n * it under the terms of the Server Side Public License, version 1,\n * as published by MongoDB, Inc.\n *\n * This program is distributed in the hope that it will be useful,\n * but WITHOUT ANY WARRANTY; without even the implied warranty of\n * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the\n * Server Side Public License for more details.\n *\n * You should have received a copy of the Server Side Public License\n * along with this program. If not, see\n * <http://www.mongodb.com/licensing/server-side-public-license>.\n */\n:local(.popoverWide) {\n  max-width: 500px;\n  min-width: 350px;\n}\n\n/* styles for table columns */\n:local(.rowTitle) {\n  width: 15%;\n}\n:local(.rowDescription) {\n  max-width: 50%;\n  width: 35%;\n}\n:local(.rowName) {\n  width: 15%;\n}\n:local(.rowCache) {\n  width: 15%;\n}\n:local(.rowAdapter) {\n  width: 15%;\n}\n:local(.rowActions) {\n  min-width: 100px;\n}\n\n:local(overviewTable) {\n  width: 100%;\n  table-layout: fixed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popoverWide": `EM8rNPeHI3I13MdYidzw`,
	"rowTitle": `oNIxQlLcjvvFY27jyODI`,
	"rowDescription": `jXZIhUCAUZtfWZKKiLy0`,
	"rowName": `Ii_DwC6G4wRm7thBYSKE`,
	"rowCache": `q54g6XMj1D9X2J48jgrw`,
	"rowAdapter": `wFLgXxYT9cnZwrNRg6DJ`,
	"rowActions": `mk2GxDtbjbYu9RWQh_nL`
};
export default ___CSS_LOADER_EXPORT___;
