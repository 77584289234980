// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FNITmrI44QcfwOlSnZnW {
  padding: 0;
  overflow-y: auto;
}

.uWCkwTOVkwo9YrCjUynr {
  font-size: 12px;
  display: table-row;
  white-space: nowrap;
}

.Zy3GorsX2QMvX8NgdDwv {
  display: flex;
}

.NIPxqjCGQchbRQbyrPIR {
  flex-grow: 1;
  margin-right: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/views/components/sidebar/fields/FieldTypeIcon.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[":local(.fieldList) {\n  padding: 0;\n  overflow-y: auto;\n}\n\n:local(.fieldListItem) {\n  font-size: 12px;\n  display: table-row;\n  white-space: nowrap;\n}\n\n:local(.filterContainer) {\n  display: flex;\n}\n\n:local(.filterInputContainer) {\n  flex-grow: 1;\n  margin-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldList": `FNITmrI44QcfwOlSnZnW`,
	"fieldListItem": `uWCkwTOVkwo9YrCjUynr`,
	"filterContainer": `Zy3GorsX2QMvX8NgdDwv`,
	"filterInputContainer": `NIPxqjCGQchbRQbyrPIR`
};
export default ___CSS_LOADER_EXPORT___;
